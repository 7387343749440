import axios from 'axios';

import { DEBUG_BASE_URL } from '../config';

const source = axios.CancelToken.source();

const instance = axios.create({
  baseURL: DEBUG_BASE_URL,
  cancelToken: source.token,
});

export default instance;
