import { postRequest } from './requests';

const LogService = {
  web: (status: boolean) =>
    new Promise((resolve, reject) => {
      postRequest('/logging', { Active: status }, resolve, reject);
    }),

  magic: (status: boolean) =>
    new Promise((resolve, reject) => {
      postRequest('/magic', { Active: status }, resolve, reject);
    }),

  instrumentation: (status: boolean) =>
    new Promise((resolve, reject) => {
      postRequest('/metrics', { Active: status }, resolve, reject);
    }),

  download: (type: string, mode: string) => {
    return `${type === 'web' ? '/logging' : '/magic'}?download=${
      mode === 'inline' ? 'inline' : 'attachment'
    }`;
  },
};

export default LogService;
