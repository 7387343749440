import React, {
  ElementType,
  Fragment,
  lazy,
  Suspense,
  FC,
  useState,
  useEffect,
} from 'react';
import { useLocation } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';

import { LoadingScreen } from '@cigam/template/dist/components';
import DashboardLayout from '@cigam/template/dist/layouts/DashboardLayout';
import { cdnHash } from '@cigam/template/dist/utils';
import { THEMES } from '@cigam/template/dist/global/constants';
import { useSettings } from '@cigam/template/dist/hooks/useSettings';
import { useDispatch, useSelector } from 'src/store';

import { AuthRoute } from 'src/components/AuthRoute';
import { GuestGuard } from 'src/components/GuestGuard';
import { MODULE } from 'src/config';
import { INavConfig, navConfig } from 'src/config/navigation';

import LogService from 'src/services/debug/logService';
import logAxios from 'src/utils/axiosDebug';
import { logout } from './Areas/Genericos/GE/slices/account';

const routesConfig = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/Error404View')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/configuracao',
    component: lazy(() => import('src/views/InitialConfig')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(
      () => import('src/Areas/Genericos/GE/views/auth/LoginCigam')
    ),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/logincontato',
    component: lazy(
      () => import('src/Areas/Genericos/GE/views/auth/LoginContato')
    ),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/home/esqueceusuasenha',
    component: lazy(
      () => import('src/Areas/Genericos/GE/views/auth/RecuperarSenha')
    ),
  },
  {
    exact: true,
    guard: AuthRoute,
    path: '/',
    component: lazy(() => import('src/Areas/Genericos/GE/views/Solutions')),
  },
  // ROTAS DESKTOP
  {
    exact: true,
    guard: AuthRoute,
    path: `${MODULE.desktop.location}/Gantt`,
    component: lazy(() => import('src/Areas/BPM/PJ/views/Gantt')),
  },
  {
    exact: true,
    guard: AuthRoute,
    path: `${MODULE.desktop.location}/TarefasProjeto`,
    component: lazy(() => import('src/Areas/BPM/PJ/views/Tarefas')),
  },
  {
    exact: true,
    guard: AuthRoute,
    path: `${MODULE.desktop.location}/BuscaAtividadesEquipe/:codigoAtividadeFrequente`,
    component: lazy(
      () =>
        import('src/Areas/Servicos/GS/views/AtividadesFrequentes/BuscaEquipes')
    ),
  },
  {
    exact: true,
    guard: AuthRoute,
    path: `${MODULE.desktop.location}/BuscaAtividadesEquipe/ListaTecnicos`,
    component: lazy(
      () =>
        import(
          'src/Areas/Servicos/GS/views/AtividadesFrequentes/BuscaEquipes/ListaTecnicos'
        )
    ),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/home/esqueceusuasenha',
    component: lazy(
      () => import('src/Areas/Genericos/GE/views/auth/RecuperarSenha')
    ),
  },
  {
    guard: AuthRoute,
    layout: DashboardLayout,
    path: '/',
    routes: [
      // ROTAS CLIENTE
      {
        exact: true,
        path: `${MODULE.portalcliente.location}`,
        component: () => (
          <Redirect to={`${MODULE.portalcliente.location}/Financas`} />
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalcliente.location}/Financas`,
        component: lazy(() => import('src/Areas/Financas/GF/views/')),
      },
      {
        exact: true,
        path: `${MODULE.portalcliente.location}/Boletos`,
        component: lazy(() => import('src/Areas/Financas/GF/views/Boletos')),
      },
      {
        exact: true,
        path: `${MODULE.portalcliente.location}/Contratos`,
        component: lazy(() => import('src/Areas/Financas/GF/views/Contratos')),
      },
      // ROTAS SERVICOS
      {
        exact: true,
        path: MODULE.portalservicos.location,
        component: () => (
          <Redirect to={`${MODULE.portalservicos.location}/Kanban`} />
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalservicos.location}/Kanban`,
        component: lazy(
          () => import('src/Areas/Servicos/GS/views/Kanban/Boards')
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalservicos.location}/Kanban/:sprint`,
        component: lazy(
          () => import('src/Areas/Servicos/GS/views/Kanban/KanbanView')
        ),
      },
      // ROTAS PORTAL CLIENTE/SERVICOS
      {
        exact: true,
        path: MODULE.portalclienteservicos.location,
        component: () => (
          <Redirect
            to={`${MODULE.portalclienteservicos.location}/MinhasOrdensServico`}
          />
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalclienteservicos.location}/MinhasOrdensServico`,
        component: lazy(
          () =>
            import('src/Areas/Servicos/GS/views/ClienteServicos/OrdensServico/')
        ),
      },
      // ROTAS BPM
      {
        exact: true,
        path: `${MODULE.portalprojetos.location}`,
        component: () => (
          <Redirect to={`${MODULE.portalprojetos.location}/AgendaTecnicos`} />
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalprojetos.location}/AgendaTecnicos`,
        component: lazy(() => import('src/Areas/BPM/PJ/views/AgendaTecnicos')),
      },
      // ROTAS INDUSTRIA
      {
        exact: true,
        path: `${MODULE.portalindustria.location}`,
        component: () => (
          <Redirect to={`${MODULE.portalindustria.location}/Dashboard`} />
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalindustria.location}/Dashboard`,
        component: lazy(
          () => import('src/Areas/Suprimentos/PC/views/Dashboard')
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalindustria.location}/LeitorProducao`,
        component: lazy(
          () => import('src/Areas/Suprimentos/PC/views/LeitorProducao')
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalindustria.location}/MovimentosProducao`,
        component: lazy(
          () => import('src/Areas/Suprimentos/PC/views/MovimentosProducao')
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalindustria.location}/PDOrdensProducao`,
        component: lazy(
          () => import('src/Areas/Suprimentos/PC/views/PDOrdensProducao')
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalindustria.location}/Relatorios/:nomePublico/:nomeRelatorio/:grupo?`,
        component: lazy(
          () => import('src/Areas/Genericos/GE/components/Relatorios')
        ),
      },
      // ROTAS FINANCEIRO
      {
        exact: true,
        path: `${MODULE.portalfinanceiro.location}`,
        component: () => (
          <Redirect to={`${MODULE.portalfinanceiro.location}/Dashboard`} />
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalfinanceiro.location}/Dashboard`,
        component: lazy(
          () => import('src/Areas/Financas/GF/views/Financeiro/Dashboard')
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalfinanceiro.location}/PDLancamentos`,
        component: lazy(
          () => import('src/Areas/Financas/GF/views/Financeiro/PDLancamentos')
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalfinanceiro.location}/Painel/:idDashboard/`,
        component: lazy(
          () => import('src/Areas/Financas/GF/components/Paineis')
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalfinanceiro.location}/Relatorios/:nomePublico/:nomeRelatorio/:grupo?`,
        component: lazy(
          () => import('src/Areas/Genericos/GE/components/Relatorios')
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalfinanceiro.location}/Lancamento/:codigoLancamento?`,
        component: lazy(
          () => import('src/Areas/Financas/GF/components/Lancamento')
        ),
      },
      // ROTAS COMPRAS
      {
        exact: true,
        path: `${MODULE.portalcompras.location}`,
        component: () => (
          <Redirect to={`${MODULE.portalcompras.location}/Dashboard`} />
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalcompras.location}/Dashboard`,
        component: lazy(
          () => import('src/Areas/Suprimentos/CO/views/Dashboard')
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalcompras.location}/PDNotasEntrada`,
        component: lazy(
          () => import('src/Areas/Suprimentos/CO/views/PDNotasEntrada')
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalcompras.location}/PDOrdensCompras`,
        component: lazy(
          () => import('src/Areas/Suprimentos/CO/views/PDOrdensCompras')
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalcompras.location}/PDSolicitacoesCompras`,
        component: lazy(
          () => import('src/Areas/Suprimentos/CO/views/PDSolicitacoesCompras')
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalcompras.location}/PDPreNota`,
        component: lazy(
          () => import('src/Areas/Suprimentos/PN/views/PDPreNota')
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalcompras.location}/PDGerenciamentoXML`,
        component: lazy(
          () => import('src/Areas/Suprimentos/PN/views/PDGerenciamentoXML')
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalcompras.location}/InteligenciaNegocios/:idDashboard/`,
        component: lazy(
          () => import('src/Areas/Suprimentos/CO/components/Paineis')
        ),
      },
      {
        exact: true,
        path: `${MODULE.portalcompras.location}/Relatorios/:nomePublico/:nomeRelatorio/:grupo?`,
        component: lazy(
          () => import('src/Areas/Genericos/GE/components/Relatorios')
        ),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    component: () => <Redirect to="/404" />,
  },
];

interface IRoutes {
  exact?: boolean;
  path?: string;
  guard?: ElementType;
  layout?: ElementType;
  component?: ElementType;
  routes?: IRoutes[];
}

interface RenderRoutesProps {
  routes: IRoutes[];
  navigation: INavConfig;
  user: {
    bio: string;
    name: string;
    avatar: string;
  };
  handleLogout: () => void;
  apmUrl?: string;
}

const renderRoutes: FC<RenderRoutesProps> = ({
  routes,
  navigation,
  user,
  handleLogout,
  apmUrl = '',
}) => {
  return routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                // @ts-ignore
                <Guard>
                  {Layout ? (
                    // @ts-ignore
                    <Layout
                      navConfig={navigation}
                      themes={THEMES}
                      user={user}
                      logService={LogService}
                      logAxios={logAxios}
                      logMonitorUrl={apmUrl}
                      logout={handleLogout}
                    >
                      {route.routes
                        ? renderRoutes({
                            routes: route.routes,
                            navigation,
                            user,
                            handleLogout,
                          })
                        : // @ts-ignore
                          Component && <Component {...props} />}
                    </Layout>
                  ) : (
                    <>
                      {route.routes
                        ? renderRoutes({
                            routes: route.routes,
                            navigation,
                            user,
                            handleLogout,
                          })
                        : // @ts-ignore
                          Component && <Component {...props} />}
                    </>
                  )}
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;
};

const chooseMenu = (location: { pathname: string }): INavConfig => {
  const matchNavigation = navConfig.find(mod => {
    const { moduleName, nav } = mod;
    const match = location.pathname.includes(moduleName);

    if (match) {
      return { moduleName, nav };
    }

    return null;
  });

  return matchNavigation || { moduleName: '', nav: [] };
};

const Routes: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigation = chooseMenu(location);
  const account = useSelector(state => state.account);
  const { settings } = useSettings();
  const [, updateState] = useState<any>();

  useEffect(() => {
    updateState({});
  }, [account.direitos]);

  const handleLogout = async () => {
    dispatch(logout());
  };

  const apmUrl = () => {
    return `${settings.apmMonitorUrl}/${cdnHash()}`;
  };

  const userName = account?.user?.NomeUsuario || '';

  const user = {
    bio: userName,
    name: userName,
    avatar: '',
  };

  return renderRoutes({
    routes: routesConfig,
    navigation,
    user,
    handleLogout,
    apmUrl: apmUrl(),
  });
};

export default Routes;
