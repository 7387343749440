import { getItemLocalStorage } from '@cigam/template/dist/utils/getItemLocalStorage';

type ConfiguracaoValue = string | boolean;

type Configuracao = {
  id: number;
  value: ConfiguracaoValue;
};

export const getConfig = (id: number) => {
  const configs: Configuracao[] = getItemLocalStorage('configuracoes');

  if (!configs) {
    return null;
  }

  const search = configs.find(config => config.id === id);

  return search?.value || null;
};

export const getConfigs = (arrId: number[]) => {
  const configs: Configuracao[] = getItemLocalStorage('configuracoes');

  return configs.filter(config => arrId.includes(config.id));
};

export const getConfigsArray = (arrId: number[]) => {
  const configs = getConfigs(arrId);
  const arrConfig: ConfiguracaoValue[] = [];

  configs.forEach(config => {
    arrConfig[config.id] = config.value;
  });

  return arrConfig;
};
