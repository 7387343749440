import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import { AppThunk } from 'src/store';
import { ContratoDTO } from '../types/dtos/contratos';

interface ContratoState {
  contratos: [ContratoDTO];
}

const initialContrato = {
  Codigo: '',
  CodigoContratante: '',
  CodigoObjeto: '',
  Objeto: '',
  DataInicio: new Date(),
  DataTermino: new Date(),
  Descricao: '',
  DiaVencimento: 0,
  Situacao: '',
};

const initialState: ContratoState = {
  contratos: [initialContrato],
};

const slice = createSlice({
  name: 'contratos',
  initialState,
  reducers: {
    setContratos(
      state: ContratoState,
      action: PayloadAction<[ContratoDTO]>
    ): void {
      state.contratos = action.payload;
    },
  },
});

export const { reducer } = slice;
export const { setContratos } = slice.actions;

export const buscarContratos =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.get(
      `financas/ct/contrato/BuscarContratosContato?$orderby=Codigo desc&$filter=Natureza eq 'C'`
    );
    dispatch(slice.actions.setContratos(response.data));
  };
