import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import { reducer as historyNavigationReducer } from 'src/Areas/Genericos/GE/slices/historyNavigation';
import { reducer as accountReducer } from 'src/Areas/Genericos/GE/slices/account';
import { reducer as kanbanReducer } from 'src/Areas/Servicos/GS/slices/kanban';
import { reducer as ordemProducaoReducer } from 'src/Areas/Suprimentos/PC/slices/ordemProducao';
import { reducer as lancamentosReducer } from 'src/Areas/Financas/GF/slices/lancamentos';
import { reducer as disponibilidadeReducer } from 'src/Areas/Suprimentos/ES/slices/disponibilidade';
import { reducer as calendarReducer } from 'src/Areas/BPM/PJ/slices/calendar';
import { reducer as contratosReducer } from 'src/Areas/Financas/GF/slices/contratos';

export const rootReducer = combineReducers({
  form: formReducer,
  historyNavigation: historyNavigationReducer,
  account: accountReducer,
  kanban: kanbanReducer,
  ordemProducao: ordemProducaoReducer,
  lancamentos: lancamentosReducer,
  disponibilidade: disponibilidadeReducer,
  calendar: calendarReducer,
  contratos: contratosReducer,
});
