export const COLORS = [
  '#E53935',
  '#8E24AA',
  '#3949AB',
  '#00897B',
  '#7CB342',
  '#FDD835',
  '#FB8C00',
  '#6D4C41',
  '#9575CD',
  '#64B5F6',
  '#4DD0E1',
  '#424242',
  '#81C784',
  '#DCE775',
  '#FFD54F',
  '#FF8A65',
  '#E0E0E0',
  '#D81B60',
  '#5E35B1',
  '#1E88E5',
  '#00ACC1',
  '#43A047',
  '#1a237e',
  '#C0CA33',
  '#FFB300',
  '#F4511E',
  '#6200ea',
  '#757575',
  '#E57373',
  '#01579b',
  '#BA68C8',
  '#006064',
  '#880e4f',
  '#1565c0',
  '#004d40',
  '#4a148c',
  '#7986CB',
  '#4FC3F7',
  '#f57f17',
  '#4DB6AC',
  '#AED581',
  '#37474f',
  '#03a9f4',
  '#FFF176',
  '#FFB74D',
  '#33691e',
  '#A1887F',
  '#90A4AE',
  '#ff9800',
  '#8bc34a',
  '#039BE5',
  '#546E7A',
  '#827717',
  '#F06292',
  '#ffc107',
  '#f44336',
  '#3e2723',
  '#1b5e20',
  '#e91e63',
  '#cddc39',
  '#607d8b',
  '#e65100',
  '#9c27b0',
  '#673ab7',
  '#795548',
  '#4caf50',
  '#3f51b5',
  '#9e9e9e',
  '#00bcd4',
  '#009688',
  '#E53935',
  '#8E24AA',
  '#3949AB',
  '#00897B',
  '#7CB342',
  '#FDD835',
  '#FB8C00',
  '#6D4C41',
  '#9575CD',
  '#64B5F6',
  '#4DD0E1',
  '#424242',
  '#81C784',
  '#DCE775',
  '#FFD54F',
  '#FF8A65',
  '#E0E0E0',
  '#D81B60',
  '#00ACC1',
  '#43A047',
  '#1a237e',
  '#C0CA33',
  '#FFB300',
  '#F4511E',
  '#6200ea',
  '#757575',
  '#E57373',
  '#b71c1c',
  '#01579b',
  '#BA68C8',
  '#880e4f',
  '#1565c0',
  '#004d40',
  '#4a148c',
  '#7986CB',
  '#f57f17',
  '#4DB6AC',
  '#AED581',
  '#37474f',
  '#FFB74D',
  '#33691e',
  '#A1887F',
  '#90A4AE',
  '#ff9800',
  '#8bc34a',
  '#546E7A',
  '#827717',
  '#F06292',
  '#ffc107',
  '#f44336',
  '#3e2723',
  '#1b5e20',
  '#e91e63',
  '#cddc39',
  '#607d8b',
  '#9c27b0',
  '#673ab7',
  '#ff5722',
  '#795548',
  '#4caf50',
  '#3f51b5',
  '#9e9e9e',
  '#00bcd4',
  '#009688',
  '#939b34',
  '#d17300',
  '#c12140',
  '#f518be',
  '#02edaa',
  '#5d47b4',
  '#e38eec',
  '#bd267f',
  '#b94749',
  '#fbbb1b',
  '#a3d017',
  '#68d7dd',
  '#11a0c3',
  '#690eb1',
  '#cf8250',
  '#314c3a',
  '#5869ce',
  '#91d1d1',
  '#d10059',
  '#1d5636',
  '#984b98',
  '#bce9bd',
  '#4c04aa',
  '#826984',
  '#751187',
  '#f31921',
  '#636f07',
  '#fb27cd',
  '#772ebf',
  '#292cbd',
  '#ffb42c',
  '#aae0ea',
  '#a25aaf',
  '#87259e',
  '#58c9d6',
  '#483ef6',
  '#c921fc',
  '#e4efb7',
  '#cbec6b',
  '#1e0c2e',
  '#f89aad',
  '#05ffb8',
  '#610d43',
  '#9265a0',
  '#d46a0b',
  '#48d519',
  '#bb7a37',
  '#3523d2',
  '#deb6c9',
  '#a90bcb',
  '#6d4b25',
  '#8f168d',
  '#35bf3b',
  '#bc126f',
  '#9e78ad',
  '#d0aaab',
  '#fddb25',
  '#aed0c6',
  '#45fc79',
  '#9864a7',
  '#8bf9d2',
  '#25b6eb',
  '#a9b1c2',
  '#5a4a09',
  '#289261',
  '#8e52b7',
  '#1077a5',
  '#90ad9a',
  '#4ba885',
  '#85914a',
  '#9d0d2c',
  '#edfc3c',
  '#721c02',
  '#015e6b',
  '#7bd807',
  '#a56a01',
  '#4e6556',
  '#70ad78',
  '#352671',
  '#9b76d3',
  '#3e4be0',
  '#003f09',
  '#7f361c',
  '#443aa2',
  '#e08a4c',
  '#9a7639',
  '#572fc0',
  '#e802cd',
  '#3c627e',
  '#c14373',
  '#0ee139',
  '#2a6b29',
  '#277eaf',
  '#9fffea',
  '#0729ed',
  '#720a65',
  '#e0605d',
  '#4b282f',
  '#fb1d23',
  '#727727',
  '#a25073',
  '#f34b87',
  '#8d688d',
  '#07eab2',
  '#452f7f',
  '#8fd289',
  '#f5fb29',
  '#b15291',
  '#a72695',
  '#78abb6',
  '#016aff',
  '#fbba42',
  '#ef14f1',
  '#16519e',
  '#627324',
  '#7d3091',
  '#3f5818',
  '#5102f5',
  '#b7c219',
  '#2a28f6',
  '#ee880d',
  '#aaf036',
  '#7ddc93',
  '#5cd7f0',
  '#3a297f',
  '#b6302a',
  '#21d419',
  '#af9378',
  '#148525',
  '#194fa7',
  '#2309ce',
  '#8ca1ac',
  '#0777b6',
  '#e7de83',
  '#2737cd',
  '#89e136',
  '#9c47d6',
  '#922075',
  '#6f9dca',
  '#3b81a5',
  '#480ac7',
  '#481276',
  '#3e7da1',
  '#766725',
  '#f59456',
  '#6a60c1',
  '#84d895',
  '#c7d1e9',
  '#24eeb2',
  '#769b4d',
  '#8088c3',
  '#964fa4',
  '#163353',
  '#3a1f26',
  '#892e3a',
  '#68d945',
  '#39a68d',
  '#020062',
  '#1a9341',
  '#349d95',
  '#936f99',
  '#9e2c07',
  '#1bfa9a',
  '#6a4275',
  '#ca437e',
  '#d1edde',
  '#3266a1',
  '#70b010',
  '#d8f1b0',
  '#e7ee41',
  '#77ccfe',
  '#fc2291',
  '#2fd0b6',
  '#c39ac5',
  '#733564',
  '#173250',
  '#da5b05',
  '#f3f2f3',
  '#1591c8',
  '#40a111',
  '#4a674d',
  '#4404d0',
  '#34b354',
  '#06e83e',
  '#77a0d6',
  '#19d09a',
  '#78645d',
  '#131e5e',
  '#e33d2c',
  '#035b6b',
  '#a79e11',
  '#6b41e5',
  '#6b4467',
  '#75a75c',
  '#0c8fa2',
  '#cab748',
  '#9cf6a8',
  '#2d8440',
  '#beeb2d',
  '#be0909',
  '#27bc70',
  '#a18a6f',
  '#c6821b',
  '#03b954',
  '#0ecfae',
  '#f307a5',
  '#e342e3',
  '#a00b10',
  '#8d7141',
  '#acc27a',
  '#08a856',
  '#173c6d',
  '#c218e8',
  '#39f0e3',
  '#679e1c',
  '#100b06',
  '#843fe7',
  '#4617f5',
  '#8f3d40',
  '#a1cd64',
  '#137be6',
  '#59e6b9',
  '#48a525',
  '#8ea954',
  '#0dec23',
  '#ebfe3f',
  '#5123c1',
  '#194973',
  '#779840',
  '#0975e6',
  '#041220',
  '#95cfbc',
  '#67efa5',
  '#7452ec',
  '#da7352',
  '#84501a',
  '#eb61f0',
  '#646fa3',
  '#3fd871',
  '#a67fc5',
  '#905805',
  '#659792',
  '#70e910',
  '#d9681e',
  '#b43241',
  '#2c5424',
  '#8f4b17',
  '#229b32',
  '#945e83',
  '#ca6e56',
  '#efb504',
  '#5bab63',
  '#4564b1',
  '#c36ba2',
  '#5a9a8d',
  '#febf29',
  '#8372d1',
  '#20daa2',
  '#4e58ee',
  '#544441',
  '#63019c',
  '#7558b0',
  '#e159f8',
  '#721b90',
  '#ee7514',
  '#c670d7',
  '#36c97a',
  '#abe372',
  '#25baaf',
  '#db77b5',
  '#355bf7',
  '#e9f8bb',
  '#122415',
  '#b55e31',
  '#970ea4',
  '#941150',
  '#bfde56',
  '#e067e8',
  '#fdcf0d',
  '#5619af',
  '#168cad',
  '#92bb9f',
  '#c83861',
  '#de8e2b',
  '#bcfb62',
  '#60cbd9',
  '#f502f9',
  '#6da09f',
  '#b45457',
  '#4b2dfa',
  '#9e00b6',
  '#fdf7dd',
  '#545fa4',
  '#73ac61',
  '#d12667',
  '#1c012f',
  '#7eaac2',
  '#84095a',
  '#e751f6',
  '#d31e46',
  '#3c42b0',
  '#b65a8d',
  '#d36939',
  '#adab84',
  '#d823fe',
  '#511916',
  '#81cb1b',
  '#bbc470',
  '#dfc8e6',
  '#1d8eb6',
  '#c01c23',
  '#a03cbc',
  '#4cd89a',
  '#6b1d40',
  '#0d03e8',
  '#931703',
  '#2a4376',
  '#e09be9',
  '#3ecedd',
  '#e79bfb',
  '#53741d',
  '#84f13a',
  '#28393d',
  '#a6d3f5',
  '#72091f',
  '#a2bb4b',
  '#19c00e',
  '#8d6b7b',
  '#4f866b',
  '#b4d6a4',
  '#ed584f',
  '#9eef2c',
  '#fda020',
  '#ccc8ea',
  '#e21a4c',
  '#bdf99a',
  '#72d4e7',
  '#34700f',
  '#b726f3',
  '#19de6b',
  '#905187',
  '#10bdad',
  '#cc6991',
  '#d926a1',
  '#7df42a',
  '#5a0d17',
  '#2147df',
  '#26f262',
  '#2635f2',
  '#cb0650',
  '#af75b1',
  '#94bb81',
  '#1f3900',
  '#6514c1',
  '#ebfc68',
  '#c1bf1d',
  '#44b28e',
  '#3c65ab',
  '#b5498a',
  '#b30ba9',
  '#bf5ef2',
];
