import { postRequest } from 'src/services/requests';
import direitos from '../global/constants/direitos';
import { DireitosDTO } from '../types/dtos/genericos';

const loadAllDireitos = async (): Promise<string[]> => {
  const data = {
    Direitos: direitos,
  };

  const responseDireitos = new Promise<DireitosDTO[]>((resolve, reject) => {
    postRequest(
      '/genericos/ge/direito/CarregarDireitos',
      data,
      resolve,
      reject
    );
  });

  return responseDireitos.then(response => {
    if (response.length) {
      const allDireitos = response
        .filter(direito => direito.Possui)
        .map(direito => direito.Chave);

      localStorage.setItem('direitos', JSON.stringify(allDireitos));

      return allDireitos;
    }

    return [];
  });
};

export { loadAllDireitos };
